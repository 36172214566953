import Siema from 'siema';

const slideButtons = [...document.querySelectorAll('.js-slide')];

if (slideButtons.length) {
    const heroSliderNode = document.querySelector('.js-heroSlider');
    const { duration } = heroSliderNode.dataset;

    // eslint-disable-next-line no-new
    const heroSlider = new Siema({
        selector: '.js-heroSlider',
        easing: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
        duration: duration || 750,
        loop: true,
        onChange: () => {
            const currentIndex = heroSlider.currentSlide;

            heroSlider.innerElements.forEach(element => {
                element.classList.remove('-active');
            });

            heroSlider.innerElements[currentIndex].classList.add('-active');

            // heroSlider.currentSlide.classList.add('-active');

            slideButtons.forEach(button => button.classList.remove('-active'));

            const correspondingButton = document.querySelector(
                `.js-slide[data-slide="${currentIndex}"]`
            );

            correspondingButton.classList.add('-active');
        },
    });

    const currentIndex = heroSlider.currentSlide;

    heroSlider.innerElements[currentIndex].classList.add('-active');

    let sliderInterval = setInterval(() => {
        heroSlider.next();
    }, 6000);

    heroSliderNode.addEventListener('mouseenter', e => {
        clearInterval(sliderInterval);
    });

    heroSliderNode.addEventListener('mouseleave', e => {
        sliderInterval = setInterval(() => {
            heroSlider.next();
        }, 6000);
    });

    slideButtons.forEach(button => {
        button.addEventListener('click', () => {
            const navIndex = button.dataset.slide * 1;

            heroSlider.goTo(navIndex);
        });
    });
}

// Logo Slider
const windowWidth = window.outerWidth;
let slidesPer = 3;

const logoCount = [...document.querySelectorAll('.js-logos .logo')].length;
const logoTray = document.querySelector('.js-logos');

if (logoTray) {
    if (windowWidth > 800) {
        slidesPer = 5;
    } else if (windowWidth < 800 && windowWidth > 450) {
        slidesPer = 3;
    } else {
        slidesPer = 2;
    }

    if (logoCount > slidesPer) {
        logoTray.classList.add('slider');

        const homepageSlider = new Siema({
            selector: '.js-logos',
            duration: 4000,
            loop: true,
            easing: 'linear',
            perPage: slidesPer,
        });

        homepageSlider.next(1);

        const continuousSlider = function applyContinuousSlider() {
            setTimeout(() => {
                requestAnimationFrame(() => {
                    homepageSlider.next(1);
                    continuousSlider();
                });
            }, 4000);
        };

        continuousSlider();
    }
}
